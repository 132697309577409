import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { cn, feadIn, HERO_BANNER_1, RESPONSIVE_BANNER_2, RESPONSIVE_BG, toAbsoluteUrl } from 'src/helpers'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import RamerMotion from 'src/shadcn/components/custom/Animations/RamerMotion'
import { Button, CarouselItem } from 'src/shadcn/components/ui'
import { motion, useInView, useScroll } from "framer-motion";
import { AnimatedText } from 'src/shadcn/components/custom/Animations/AnimatedText'

type Props = {}

const Banner1 = (props: Props) => {

  const navigate = useNavigate()

  return (
    <CarouselItem >
      <div
        className={cn(`w-full lg:h-[650px] h-max relative`)}
        style={{
          background: `url(${RESPONSIVE_BG})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center"

        }}
      >
        <div className='flex justify-center xl:justify-end xl:mt-4'>
          <img src={RESPONSIVE_BANNER_2} className='lg:right-0 lg:absolute xl:p-20 pt-24 px-4' />
        </div>
        <div className=" md:w-[500px]    xl:w-[500px]  mx-auto lg:mx-0   space-y-3 2xl:ml-40  pb-10 px-10 w-full">
          <div className='lg:pt-[15vh]'>
            <div className='md:w-[450px]'>
              <img src={toAbsoluteUrl("/media/svg/Electricity_PM.svg")} style={{ height: "100px" }} className='mb-4' />
              <div className='space-y-1'>

                <div className="font-sans font-bold text-3xl md:text-4xl uppercase ">
                  One Smart Decision,
                </div>
                <div className="font-sans font-bold text-3xl md:text-4xl uppercase ">Twice The Impact</div>


              </div>
              <div className='h-[2px] bg-primary w-[390px] my-4'></div>
            </div>
            <div className='my-5'>
              <div className='my-5'>Opt for renewable energy to reduce your electricity bill <br /> while building a sustainable and a greener future</div>
            </div>
            <div >
              <div className='font-bold'>Your partner for:</div>
              <ul className='list-disc ml-4'>
                <li>Choosing financing options at competitive rates.</li>
                <li>Access to high-quality materials with warranties.</li>
                <li>Ensuring excellent workmanship with trusted partners.</li>
              </ul>
            </div>
          </div>
          <div className='flex space-x-2'>
            <Button onClick={() => {
              const violation: any = document.getElementById("welcome-services-residential-section");
              window.scrollTo({
                top: violation.offsetTop - 50,
                behavior: "smooth"
              });
            }} className='w-[150px]'>Learn More</Button>
            <Button onClick={() => navigate("/" + MAIN_ROUTES_CONST.contact_us)} variant={'theamBlack'} className='w-[150px]'>Contact Us</Button>
          </div>
        </div>
      </div>
    </CarouselItem>
  )
}

export default Banner1