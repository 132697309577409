import React from 'react'
import WhyUs from './Component/WhyUs'
import { ANNOTATION_ICON } from 'src/helpers'

const WhyUsMain = () => {
  return (
    <div className='pt-16  font-sans sm:pt-16 sm:pb-8'>
      <div className='px-16 sm:px-16 sm:mb-16'>
      <div className="flex items-center space-x-2">
      <img
        src={ANNOTATION_ICON}
        alt="Annotation Icon"
        className="w-5 h-5 sm:w-6 sm:h-6"
      />
      <span className="font-bold text-xl sm:text-3xl">Why Us</span>
    </div>
        {/* <div className='flex '> <img src={ANNOTATION_ICON} style={{ width: "20px", height: "20px" }} />
         <span className=' font-bold text-3xl'>Why Us</span>
        </div> */}
        <div className='ml-6 sm:ml-[25px] text-sm sm:text-base'>Expert solutions and reliable support for your solar journey.</div>
      </div>
      <WhyUs />
    </div>
  )
}

export default WhyUsMain