import React from 'react'

import HeroSection from 'src/modules/ReStructure/Welcome/HeroSection'

import EnquiryFormMain from 'src/modules/ReStructure/Welcome/EnquiryForm/EnquiryFormMain'
import WhyGoToSolarMain from 'src/modules/ReStructure/Welcome/WhyGoToSolar/WhyGoToSolarMain'
import WhyWeDoMain from 'src/modules/ReStructure/Welcome/WhyWeDo/WhyWeDoMain'
import P2pTrading from 'src/modules/ReStructure/Welcome/P2PTrading/P2pTrading'
import WhyUsMain from 'src/modules/ReStructure/Welcome/WhyUs/WhyUsMain'
import ReferAndEarn from 'src/modules/Layouts/ReStructure/Footer/ReferAndEarn/ReferAndEarn'
import WelcomeServices from 'src/modules/ReStructure/Welcome/Services'
import BlockchainService from 'src/modules/ReStructure/Welcome/BlockchainService'
import FaqSection from 'src/modules/ReStructure/Welcome/Faq'
import TestimonialSlider from 'src/modules/ReStructure/Welcome/TestimonialSlider'
import Features3 from 'src/modules/ReStructure/Welcome/Features3'
import OurProducts from 'src/modules/ReStructure/Welcome/Products'
import HeroSection3 from 'src/modules/ReStructure/Welcome/HeroSection3/HeroSection3'


const WelcomePage_R = () => {
  return (

    <div className='w-full  mb-[200px] '>
      <div className='w-full relative'>
        {/* <div className='w-full z-[90] -mt-20'>
            <HeroSection />
          </div> */}
        {/* <div className='w-full absolute bottom-0 '>
          <EnquiryFormMain />
        </div> */}
      </div>
      <div className='w-full -mt-20 '>
        <HeroSection3 />
      </div>
      {/* <Features3 /> */}
      {/* <WhyGoToSolarMain /> */}
      {/* <WhyWeDoMain /> */}
      <WelcomeServices />
      <div className='mt-20'>
        <P2pTrading />
      </div>
      <BlockchainService />
      <WhyUsMain />
      {/* <OurProducts /> */}
      {/* <TestimonialSlider /> */}
      {/* <FaqSection /> */}

      {/* <TestimonialSlider /> */}
    </div>

  )
}

export default WelcomePage_R