import React from "react";
import { cn } from "src/helpers";
interface IEnquiryFormTab {
  value: string;
  label: string;
}
interface EnquiryFormTabProps {
  setEnquiryTab: (val: string) => void;
  enquiryTab:string
}
const EnquiryFormTab = ({ setEnquiryTab,enquiryTab }: EnquiryFormTabProps) => {
  const EnquiryFormTabArr: IEnquiryFormTab[] = [
    {
      value: "residential",
      label: "Residential",
    },
    {
      value: "commercial",
      label: "Commercial",
    },
    {
      value: "society",
      label: "Society",
    },
  ];
  return (
    <div className="w-full  items-center flex ">
      <div className="flex space-x-4 justify-start">
        {EnquiryFormTabArr?.map(({ label, value }, index) => (
          <div
            className={cn("w-[132px] h-[44px] p-[10px] bg-white text-center cursor-pointer",{"bg-[#575451D9] text-white":enquiryTab===value})}
            key={`${value}-${index}`}
            onClick={() => setEnquiryTab(value)}
          >
            {label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default EnquiryFormTab;
