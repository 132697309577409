import { useNavigate } from "react-router-dom";
import { ANNOTATION_ICON, feadIn, WELCOME_SERVICE_BG } from "src/helpers";
import { Button } from "src/shadcn/components/ui";
import CommercialServicesCarousal from "./CommercialServices/CommercialServicesCarousal";
import RecidentialServices from "../../Services/RecidentialServices";
import ResidentialServicesCarousal from "./ResidentialServices/ResidentialServicesCarousal";
import { MAIN_ROUTES_CONST } from "src/navigations/modules";
import { motion, useInView, useScroll } from "framer-motion";

type Props = {};

const WelcomeServices = (props: Props) => {
  const navigate = useNavigate();

  return (
    <div className="bg-[#EEEDEC]">
      <div
        style={{
          background: `url(${WELCOME_SERVICE_BG})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
        className="w-full"
      >
        
        <div
          id="welcome-services-commercial-section"
          className="space-y-8 pt-16 pb-8"
        >
          {/* <motion.div initial="hidden" whileInView="show" viewport={{ amount: 0.8 }} variants={feadIn("left", 0.1)} className='px-16'> */}
          <div className="sm:px-16 px-6">
            <div className="flex sm:space-x-1 space-x-2">
              {" "}
              <img
                src={ANNOTATION_ICON}
                style={{ width: "20px", height: "20px" }}
              />{" "}
              <span className=" font-bold text-3xl">
                Commercial & Industrial(CI)
              </span>
            </div>
            <div className="ml-[25px]">
              Powering businesses with reliable and efficient solar solutions
            </div>
          </div>
          {/* </motion.div> */}
          <div className="sm:px-16 px-6">
            <CommercialServicesCarousal />
          </div>
          <div className="flex justify-center">
            <Button
              onClick={() =>
                navigate("/" + MAIN_ROUTES_CONST.commercial_services)
              }
              className="w-[150px]"
            >
              Learn More
            </Button>
          </div>
        </div>
        
        <div className=" border-t-2 border-dashed border-primary my-2"></div>
        <div
          id="welcome-services-residential-section"
          className="space-y-8 pt-16 pb-8"
        >
          {/* <motion.div initial="hidden" whileInView="show" viewport={{ amount: 0.8 }} variants={feadIn("left", 0.1)} className='px-16'> */}

          <div className=" px-6 md:px-16">
            <div className="flex sm:space-x-1 space-x-2">
              {" "}
              <img
                src={ANNOTATION_ICON}
                style={{ width: "20px", height: "20px" }}
              />{" "}
              <span className=" font-bold text-3xl ">
                Residential & Societies(RS)
              </span>
            </div>
            <div className="ml-[25px]">
              Solar solutions for your homes and communities
            </div>
          </div>

          {/* </motion.div> */}

          <div className="md:px-16">
            <ResidentialServicesCarousal />
          </div>
          <div className="flex justify-center">
            <Button
              onClick={() =>
                navigate("/" + MAIN_ROUTES_CONST.residential_services)
              }
              className="w-[150px]"
            >
              Learn More
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeServices;
