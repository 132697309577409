import React from 'react'
import { toAbsoluteUrl } from 'src/helpers'

type Props = {}

const Mission = (props: Props) => {
    return (
        <div>
            <div className=' grid md:grid-cols-4 gap-4' >
            <div className='h-[100px] w-[100px] md:h-[120px] md:w-[120px] mx-auto bg-gray-200 flex justify-center items-center rounded-full'>
                    <img src={toAbsoluteUrl("/media/png/mission_icon.png")} style={{  height: "70px" }} />
                </div>
                <div className='md:col-span-3 flex flex-col items-center md:items-start space-y-2 text-center'>
                    <div className='font-bold text-xl' >Mission</div>
                    <div className='w-[300px] md:w-full text-center md:text-start'>
                    Making Renewable Energy Accessible through Digital Innovation: Our mission is to democratize clean energy by developing user-friendly digital platforms that connect individuals and businesses to sustainable power solutions, promoting fair energy access and empowering communities worldwide.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mission