import React from 'react'
import { ANNOTATION_ICON, toAbsoluteUrl } from 'src/helpers'
import Vision from './Vision'
import Mission from './Mission'

type Props = {}

const VisionAndMIssionPage = (props: Props) => {
    return (
        <div className='space-y-12'>
            <div className='h-[300px]' style={{
                background: `url(${toAbsoluteUrl("media/jpg/MissionAndVissioBanner.png")})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center"
            }}>
                <div className='flex justify-center items-center h-full w-full '>
                    <div className='flex flex-col items-center'>
                        <div className='font-bold text-4xl'>Our</div>
                        <div className='font-bold text-4xl'>Vision & Mission</div>
                    </div>
                </div>
            </div>
            <div className='mx-16'>
            <div className='flex space-x-1'>
            <img src={ANNOTATION_ICON} style={{ width: "20px", height: "20px" }} /> <span className=' font-bold text-3xl'>Our Vision & Mission</span></div>
            </div>
            <div className=' md:max-w-[60vw] mx-auto space-y-16 '>
                <Vision />
                <div className=' border-b-2 border-dashed border-primary'></div>
                <Mission />
            </div>
        </div>
    )
}

export default VisionAndMIssionPage