import React, { useState } from "react";
import { Button, Card } from "src/shadcn/components/ui";
import { initialPaymentForm, IPaymentForm, ISolarPlanArr, PAYMENT_FORM_FIELDS, paymentFormSchema } from "./modules";
import { checkDecimal, formatIndianNumber } from "src/helpers";
import { Check } from "lucide-react";
import HtmlCodeRender from "src/shadcn/components/custom/HtmlCodeRender";
import CustomeDialog from "src/shadcn/components/custom/CustomeDialog";
import RenderFormComponents from "src/shadcn/components/custom/RenderFormComponents";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useAuth } from "src/modules/Auth/Core";

const SolarplanCard = ({
  benefits,
  emiPrice,
  name,
  overAllPrice,
  projectPrice,
  unlockPrice,
  features,
  typicalRate,
  interestSavings
}: any) => {

  console.log({typicalRate});
  

  const [isLoading, setisLoading] = useState(false)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const navigate = useNavigate()
  const { currentUser } = useAuth()

  const formik = useFormik<IPaymentForm>({
    initialValues: initialPaymentForm,
    validationSchema: paymentFormSchema,
    onSubmit: (values, { setSubmitting }) => {
      // Handle form submission here
      //handleEnquiryForm()
    },
  });

  return (
    <>
      <Card className=" font-sans bg-[#E0995833] border border-t-[#BF875426] border-t-[4px] rounded-[26px] w-[413px] h-[458px] drop-shadow-lg p-9 space-y-4">
        <h1 className="text-2xl font-bold text-primary text-center uppercase">{name}</h1>
        <div className="text-[#231D4F] flex justify-center items-center text-2xl space-x-3 ">
          <div className="font-normal text-nowrap">Project Price :</div>
          <div className="font-bold text-nowrap">
            ₹{""} {checkDecimal(projectPrice?.toString(), true)}
          </div>
        </div>

        <div className="grid grid-cols-2 ">
          <div className="space-y-1">
            <div className="text-sm font-bold text-primary">Overall Price</div>
            <div className="text-[#231D4F99] font-semibold text-nowrap">
              <del>
                ₹ {checkDecimal(Number(overAllPrice?.totalPrice)?.toString(), true)}
              </del>
            </div>
            <div className="text-[#231D4F] font-bold">
              ₹ {checkDecimal((overAllPrice?.discountPrice)?.toString(), true)}
            </div>
          </div>
          <div className="space-y-1">
            <div className="text-sm font-bold text-primary">
              EMI ( 12 Months )
            </div>
            <div className="text-[#231D4F99] font-semibold text-nowrap">
              <del>
                ₹ {checkDecimal(emiPrice?.totalPrice?.toString(), true)}
              </del>
            </div>
            <div className="text-[#231D4F] font-bold">
              ₹ {checkDecimal((emiPrice?.discountPrice)?.toString(), true)}
            </div>
          </div>
        </div>

        <div className="space-y-2">
          {benefits?.map((cur: string, index: number) => (
            <div className="flex items-center space-x-4" key={`${cur}-${index}`}>
              <div className="w-[24px] h-[24px] rounded-full bg-[#FF790026] text-primary flex justify-center items-center">
                <Check className="w-4 h-4" strokeWidth={3} />
              </div>
              <div className="text-sm font-semibold">
                <HtmlCodeRender htmlString={cur} />
              </div>
            </div>
          ))}
        </div>

        <Button onClick={() => setShowDialog(true)} className="rounded-full text-sm font-normal h-[43px]">Unlock <span className="font-bold">₹ {formatIndianNumber(
          unlockPrice.toString(),
          2
        )}</span> in Interest Savings Over Time </Button>

      </Card>

      <CustomeDialog className='' isOpen={showDialog} headerTitle={"Order Details "} isLoading={isLoading} headerTitleClassName="uppercase text-primary font-bold" onCancle={() => { setShowDialog(false) }} onSubmit={() => formik.handleSubmit()}    >
        <div className="grid grid-cols-2 space-y-4">
          <div>
            <div className="rounded-lg">
              <h2 className="text-xl font-bold mb-2">{name} Plan</h2>
              <p className="text-gray-700">Project Price: ₹ {projectPrice}</p>
              <p className="text-gray-700">Overall Price: ₹ {overAllPrice.totalPrice}</p>
              <p className="text-gray-700">EMI (12 Months): ₹ {emiPrice.totalPrice}</p>
            <div className="mt-4">
                <h3 className="font-semibold">Features:</h3>
                <ul className="list-disc list-inside">
                  <li>Age: {features?.age}</li>
                  <li>Efficiency: {features?.efficiency}</li>
                  <li>Brand Origin: {features?.brandOrigin}</li>
                  <li>Subsidy Eligible: {features?.subsidyEligible ? 'Yes' : 'No'}</li>
                </ul>
              </div>
                <p className="text-gray-700 mt-2">Typical Rate: {typicalRate} kW</p>
              <p className="text-gray-700">Interest Savings Over Time: ₹ {interestSavings}</p>
            </div>
          </div>
          <div className='md:grid md:grid-cols-2 overflow-y-scroll h-[300px] md:h-max md:overflow-hidden gap-4 pb-4 '>
            {
              PAYMENT_FORM_FIELDS.map((field: any) => {
                return <RenderFormComponents key={`payment-form-${field.dataKey}`} formik={formik} value={formik.values[field.dataKey as keyof object]} {...field} onChange={(e: any) => formik.setFieldValue(field.dataKey, e)} />
              })
            }
          </div>
        </div>
      </CustomeDialog>
    </>
  );
};

export default SolarplanCard;
