import FilterMain from 'src/modules/Marketplace/Filters/FilterMain'
import React, { useEffect, useMemo, useState } from 'react'
import ResultFilter from 'src/modules/Marketplace/Result/ResultFilter'
import { Separator } from 'src/shadcn/components/ui/separator'
import { catchAsync, stringifyObjectToRequestQuery, toAbsoluteUrl } from 'src/helpers'
import { getAllProjectsRequestApi } from 'src/services/requests/Projects'
import { AxiosResponse } from 'axios'
import { Skeleton } from 'src/shadcn/components/ui/skeleton'
import ProjectResultsLoader from 'src/modules/Marketplace/Loaders/ProjectResultsLoader'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import Marketplacesheet from './Marketplacesheet'

type Props = {}

const MarketplaceMain = (props: Props) => {



  const [projects, setprojects] = useState([])
  const [isLoading, setisLoading] = useState(false)
  const [open, setopen] = useState(false)
  useEffect(() => {
    handleGetAllProjects()
    return () => { }
  }, [])


  const handleGetAllProjects = catchAsync(async (query?: string) => {
    setisLoading(true)
    return await getAllProjectsRequestApi(query)
  }, (results: AxiosResponse) => {
    setprojects(results.data)
    setisLoading(false)
  }, () => {
    setisLoading(false)
  })

  const handleFilters = (e: any) => {
    delete e["price"]
    console.log({ filter: e });
    let prepareQueryString = stringifyObjectToRequestQuery(e)
    handleGetAllProjects(prepareQueryString)
  }

  const totalProjectSize = useMemo(() => {
    if (projects && Array.isArray(projects) && projects.length > 0) {
      return (projects.reduce((acc: any, curr: any) => acc += Number(curr.projectSize), 0) / 1000)
    }
    return 0
  }, [projects])


  return (
    <div className='mb-32 md:mb-0'>
      {/* <div className='w-full h-[300px] overflow-hidden' style={{ backgroundImage: "url(" + toAbsoluteUrl("/media/jpg/marketExplorerBanner.png") + ")", backgroundSize: "cover" }} >
        <div className='flex flex-col justify-center items-center h-[100%] space-y-2'>
          <div className='text-white font-bold md:text-3xl text-2xl' >
            <div className='text-center'>Discover, Compare, and</div>
            <div className='text-center'>Choose Solar Project Experience</div>
          </div>
          <div className='text-white text-center ' >Explore a wide range of solar projects <br /> tailored to meet your solar projects needs.</div>
        </div>
      </div> */}
      <div className='mb-8 h-auto'>
        <video autoPlay loop muted style={{ width: "1580px" }} >
          <source src={toAbsoluteUrl("/media/adds/marketplace/Add3.mp4")} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      {/* <div className='flex flex-col items-center py-8 space-y-4'>
        <div className='text-center  text-3xl '>
          Find <span className='font-bold'>Solar Projects </span> of your choice on
        </div>
        <img src={toAbsoluteUrl("/media/svg/Electricity_PM.svg")} style={{ height: "80px" }} className='mb-4' />
      </div> */}
      <div className='w-full md:w-[90vw] mx-auto grid md:grid-cols-4 gap-6'>
        <div className='p-2 hidden md:flex md:flex-col'>
          <FilterMain onChange={handleFilters} />
        </div>
        <div className='md:hidden flex ml-4'>
          <Marketplacesheet children={<FilterMain onChange={handleFilters} open={open} setopen={setopen}/>} open={open} setopen={setopen}/>
          
        </div>

        <div className='md:col-span-3 p-2 space-y-2 '>
          <div className='flex justify-between items-center'>
            <div className='uppercase text-primary font-bold'>Projects</div>
            <div className='flex space-x-2'>
              <div className='flex text-sm'><span className='text-gray-400'>Total : </span><span>{projects.length}</span></div>
              <Separator orientation='vertical'  />
              <div className='flex text-sm'><span className='text-gray-400'>Total Project Size : </span><span>{totalProjectSize} MW</span></div>
            </div>
          </div>
          {
            isLoading ? <div className='flex flex-col space-y-2'>
              {
                [...new Array(3)].map((_) => {
                  return <ProjectResultsLoader />
                })
              }
            </div> : <ResultFilter projects={projects} loading={isLoading} />
          }
        </div>

      </div>
    </div>
  )
}

export default MarketplaceMain