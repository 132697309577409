import React from 'react'
import { toAbsoluteUrl } from 'src/helpers'
import OurTeamCarousal from 'src/modules/OurTeams/OurTeamCarousal'

type Props = {}

const OurTeamPage = (props: Props) => {
    return (
        <div className=''>
             <img src={toAbsoluteUrl("/media/jpg/OurTeamBg.png")} className='h-[150px] md:h-[450px] w-full' />
            <div className='w-[80vw] mx-auto pt-16 pb-8  space-y-8'>
                <div>
                    <div className='font-bold  text-center text-primary text-2xl uppercase'>Meet Our Team</div>
                    <div className='text-center'>The Talent Powering Our Innovation </div>
                </div>
                <div className='text-center'>
                    The Orange Current has an eclectic, passionate, and multi-faceted team working towards creating a sustainable and future-focused energy company. United by a shared vision, we blend innovation with expertise to solve the most pressing energy challenges. Our team thrives on collaboration, creativity, and commitment to building a cleaner, greener, and more efficient future for generations to come.
                </div>
                <div className=''>
                    <OurTeamCarousal />
                </div>
            </div>
        </div>
    )
}

export default OurTeamPage