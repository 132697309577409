import { AVAILABLE_SPACE_UNITS_OPTIONS, DISCOMS_OPTIONS, FormField, NUMBER_REGEX } from "src/helpers";
import * as Yup from 'yup';
export interface IEnquiryForm {
    pincode: string,
    phoneNumber: string,
    discom: string,
    monthlyBillAmount: string,
    area: string,
    lat:string,
    long:string
    
}
export const ENQUIRY_FORM_FIELDS: FormField[] = [

    {
        label: '',
        dataKey: 'pincode',
        componentType: 'input',
        componentProps: {
            type: 'text',
            regex:NUMBER_REGEX,
            placeholder: 'Pincode *',
            maxLength:10
        },
    },
    {
        label: '',
        dataKey: 'discom',
        componentType: 'options',
        componentProps: {
            options:DISCOMS_OPTIONS
        },
    },
    {
        label: '',
        dataKey: 'monthlyBillAmount',
        componentType: 'input',
        componentProps: {
            type: 'text',
            regex:NUMBER_REGEX,
            placeholder: 'Monthly bill',
            maxLength:10
        },
    },
    {
        label: '',
        dataKey: 'area',
        componentType: 'input',
        componentProps: {
            type: 'number',
            numberFormatted:true,
            options:AVAILABLE_SPACE_UNITS_OPTIONS,
            placeholder: 'Area Sqft'
        },
    },
    {
        label: '',
        dataKey: 'phoneNumber',
        componentType: 'input',
        componentProps: {
            type: 'text',
            regex:NUMBER_REGEX,
            placeholder: 'Enter your phone number',
            maxLength:10
        },
    },

];

export const ENQUIRY_INIT: IEnquiryForm = {
    pincode: '',
    phoneNumber: '',
    discom: '',
    monthlyBillAmount: '',
    area: '',
    lat:"",
    long:""
  };

  export const EnquiryFormSchema = Yup.object().shape({
    
    phoneNumber: Yup.string().required('Phone number is required'),
    pincode: Yup.string().required('Pincode is required'),
    discom: Yup.string().required('Discom is required'),
    monthlyBillAmount: Yup.string().required('Monthly Bill is required'),
    area:Yup.string().required('Area  is required'),
  
});