import React, { useState } from "react";
import EnquiryForm from "./Component/EnquiryForm";
import { PhoneCall } from "lucide-react";
import EnquiryFormTab from "./Component/EnquiryFormTab";

const EnquiryFormMain = () => {
  const [enquiryTab, setEnquiryTab] = useState('commercial')
  return (
    <div className="w-full">
      <EnquiryFormTab setEnquiryTab={setEnquiryTab} enquiryTab={enquiryTab} />
      <div className="w-full flex justify-center items-center">
        <div className=" flex">
          <div className=" bg-[#575451D9]  p-6">
            <h1 className="text-[16px] text-white font-bold font-sans px-2">
              Enquiry Now
            </h1>
            <EnquiryForm />
          </div>
          {/* <div className="bg-primary p-6 w-[25%] text-white flex flex-col items-center justify-center space-y-4">
            <PhoneCall className="text-white w-[50px] h-[50px]" />
            <div className="space-y-1">
              <div className="text-xl font-normal text-center">Call Today</div>
              <div className="text-2xl font-bold text-center">0000 000 000</div>
            </div>

          </div> */}
        </div>
      </div>
    </div>
  );
};

export default EnquiryFormMain;
