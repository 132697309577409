import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
  ENQUIRY_FORM_FIELDS,
  ENQUIRY_INIT,
  EnquiryFormSchema,
} from "./modules";
import RenderFormComponents from "src/shadcn/components/custom/RenderFormComponents";
import RoundedButton from "src/shadcn/components/custom/RoundedButton";
import { useDispatch } from "react-redux";
import { catchAsync, cn } from "src/helpers";
import { createQuotationRequestRequestApi } from "src/services/requests/QuotationRequests";
import { toast } from "sonner";
import { setQuotationdetails } from "src/redux/reducers/tempDataSlice";
import CustomeDialog from "src/shadcn/components/custom/CustomeDialog";
import { Button } from "src/shadcn/components/ui";
import { useNavigate } from "react-router-dom";
import { MAIN_ROUTES_CONST } from "src/navigations/modules";

const EnquiryForm = () => {

  const [isLoading, setisLoading] = useState(false)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [fetchingLatLong, setfetchingLatLong] = useState<boolean>(false)
  const [showLoactionDailog, setShowLoactionDailog] = useState<boolean>(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    getMyLocation()
    return () => {
    }
  }, [])


  const formik = useFormik({
    initialValues: ENQUIRY_INIT,
    validationSchema: EnquiryFormSchema,
    onSubmit: (values, { setSubmitting }) => {
      // Handle form submission here
      // handleRegistrationForm()
      // getMyLocation()
      if (!formik.values?.lat && !formik.values?.long) {
        setShowLoactionDailog(true)
      }
      else handleSubmitForm()
    },
  });


  const getMyLocation = () => {
    setfetchingLatLong(true);
    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition(
        (position) => {
          setfetchingLatLong(false);
          formik.setFieldValue("lat", position.coords.latitude);
          formik.setFieldValue("long", position.coords.longitude);
          // handleSubmitForm({ lat: position.coords.latitude, long: position.coords.longitude })
        },
        (error) => {
          setfetchingLatLong(false);
          // if (error.code === error.PERMISSION_DENIED) {
          //   toast.error("Location access denied. Please enable location services in your browser.");
          // } else {
          //   toast.error("Unable to retrieve your location.");
          // }
        }
      );
    } else {
      toast.error("Geolocation is not supported by this browser.");
      setfetchingLatLong(false);
    }
  };


  const handleSubmitForm = catchAsync(async () => {
    setisLoading(true)
    return await createQuotationRequestRequestApi({ ...formik.values })
  }, (result: any) => {
    if (result.status == 201) {
      console.log({ result_data: result.data });
      setisLoading(false)
      toast.success("Request submited..")
      dispatch(setQuotationdetails({ ...formik.values, ...result.data }))
      // document.getElementById("quotation-details")?.scrollIntoView({
      //   behavior: "smooth",
      //   block: "center"
      // });
      setShowDialog(true)
      formik.resetForm()
      getMyLocation()
      navigate("/" + MAIN_ROUTES_CONST.customerJourney)
    }
  }, () => { setisLoading(false) })


  return (
    <div className="">
      <div className="space-y-2">
        <form className="space-y-2" onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-2 align-middle p-4">
            {ENQUIRY_FORM_FIELDS.map((field: any) => {
              return (
                <RenderFormComponents
                  key={`login-form-${field.dataKey}`}
                  formik={formik}
                  {...field}
                  value={formik.values[field.dataKey as keyof object]}
                  onChange={(e: any) => formik.setFieldValue(field.dataKey, e)}
                />
              );
            })}
            <div className="mt-4">
              <Button
                variant={'outlinePrimary'}
                isLoading={false}
                disabled={isLoading}
                type="submit"
                className={cn("w-full bg-orange-100 hover:text-black rounded-md", { "bg-black/10 border-none text-white hover:bg-white ": isLoading })}
              >
                {isLoading ? "Loading..." : "Submit Your Request"}
              </Button>
            </div>
          </div>
        </form>
      </div>
      <CustomeDialog isOpen={showDialog} headerTitle={"Request Submited"} hideDefaultCancle onSubmit={() => { setShowDialog(false) }} >
        <p className='pb-4'>Thank You for showing your interest, our team will contact you soon.</p>
      </CustomeDialog>
      <CustomeDialog isOpen={showLoactionDailog} headerTitle={"Allow Location"} hideDefaultCancle onSubmit={() => {
        setShowLoactionDailog(false);
        // window.location.reload()
      }} >
        <p className='pb-4'>Please allow browser location </p>
      </CustomeDialog>
    </div>
  );
};

export default EnquiryForm;
